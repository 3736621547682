(function() {
    
    const isOverflown = (element) => {
        element
            .closest('.show-more-item')
            .classList.remove('js-require-show-more');
        
        element
            .closest('.show-more-item')
            .querySelector('.show-more-item__button')
            .setAttribute('aria-expanded', 'false');

        element.classList.remove('showing');

        return (element.scrollHeight > element.clientHeight + 30);
    }

    const addRemoveOverflowClass = (element) => {
        if (isOverflown(element)) {
            element
                .closest('.show-more-item')
                .classList.add('js-require-show-more');
        } else {
            element
                .closest('.show-more-item')
                .classList.remove('js-require-show-more');
        }
    }

    const debounce = (func, delay) => {
        let timeoutId;
        
        return () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(func, delay);
        };
    }

    const customEvent = new Event('resizeShowMoreDone');
    const handleResizeShowMoreDone = () => {
        window.dispatchEvent(customEvent);
    }

    const debouncedResizeHandler = debounce(handleResizeShowMoreDone, 300);
    
    let windowWidth = window.innerWidth;
    let newWindowWidth;
    
    window.addEventListener('resize', () => {
        newWindowWidth = window.innerWidth;
        
        if (windowWidth !== newWindowWidth) {
            debouncedResizeHandler();
        }

        windowWidth = newWindowWidth;
    });

    const setHeightToBreak = (parent, element) => {
        if (element) {
            parent.style.removeProperty('--show-more-fade-offset');
            
            const fadeOffset = getComputedStyle(parent).getPropertyValue('--show-more-fade-offset');
            parent.style.setProperty('--show-more-max-height', `${Math.round(element.getBoundingClientRect().top - parent.getBoundingClientRect().top) + parseInt(fadeOffset)}px`);
        }
    }

    const setHeightToAttribute = (parent) => {
        const dataAttrHeight = parent.getAttribute('data-show-more-height');
        if (dataAttrHeight) {
            const fadeOffset = getComputedStyle(parent).getPropertyValue('--show-more-fade-offset');
            
            parent.style.setProperty('--show-more-max-height', `${parseInt(dataAttrHeight) + parseInt(fadeOffset)}px`);
        }
    }

    const showMoreEl = document.querySelectorAll('.show-more-item');
    showMoreEl.forEach(item => {
        const showMoreToggleMarkup = /* html */`
            <a class="show-more-item__button" role="button" href="#" aria-expanded="false">
                <span class="show-more-item__text-closed">Show more</span>
                <span class="show-more-item__text-open">Show less</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true">
                    <!--
                        !Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com
                        License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.
                    -->
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                </svg>
            </a>
        `;

        const showMoreControlsEl = document.createElement('div');
        showMoreControlsEl.classList.add('show-more-item__controls');
        showMoreControlsEl.innerHTML = showMoreToggleMarkup;
        const showMoreButtonEl = showMoreControlsEl.querySelector('.show-more-item__button');
        item.appendChild(showMoreControlsEl);

        const collapsibleItem = item.querySelector('.show-more-item__content');

        setHeightToAttribute(item);

        const breakElement = item.querySelector('.show-more-item__break');
        setHeightToBreak(item, breakElement);

        showMoreButtonEl.addEventListener('click', (event) => {
            event.preventDefault();
            
            showMoreButtonEl.setAttribute('aria-expanded', (showMoreButtonEl.getAttribute('aria-expanded') === 'false') ? 'true': 'false');

            let transitionTimer;
            clearTimeout(transitionTimer);
            
            if (showMoreButtonEl.getAttribute('aria-expanded') === 'true') {
                collapsibleItem.style.maxHeight = collapsibleItem.scrollHeight + 'px';
                
                transitionTimer = setTimeout(() => {
                    collapsibleItem.classList.add('showing');
                    collapsibleItem.style.maxHeight = '';
                }, 300);
            } else {
                collapsibleItem.style.maxHeight = collapsibleItem.scrollHeight + 'px';
                collapsibleItem.classList.remove('showing');

                transitionTimer = setTimeout(() => {
                    collapsibleItem.style.maxHeight = '';
                }, 10);
            }
        });

        addRemoveOverflowClass(collapsibleItem);

        window.addEventListener('resizeShowMoreDone', () => {
            setHeightToAttribute(item);
            setHeightToBreak(item, breakElement);
            addRemoveOverflowClass(collapsibleItem);
        });

        document.fonts.ready.then(() => {
            setHeightToAttribute(item);
            setHeightToBreak(item, breakElement);
            addRemoveOverflowClass(collapsibleItem);
        });
    });
})();